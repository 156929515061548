<template>
  <div class="form-group">
    <div :class="checkboxGroupClass">
      <div class="checkbox-group">
        <input :type="props.type" :id="inputId" :class="checkboxInputClass" :checked="modelValue" :required="isRequired"
                @change="handleInput" />
        <label :for="inputId" :class="checkboxLabelClass" v-html="labelText"></label>
      </div>
      <p :id="`${inputId}-error`" class="gibs-form-group-error gibs-small-regular"></p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue';

const props = defineProps({
  type: {
    type: String,
    required: true,
    default: "checkbox"
  },
  inputId: {
    type: String,
    required: true,
  },
  labelText: {
    type: String,
    required: true,
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  checkboxInputClass: {
    type: String,
    default: 'contact-form-checkbox',
  },
  checkboxLabelClass: {
    type: String,
    default: 'contact-form-label',
  },
  checkboxGroupClass: {
    type: String,
    default: 'checkbox-group',
  },
  validator: {
		type: Validation,
		default: null,
	},
	validatorRuleId: {
		type: String,
		default: null
	}
});

const emitFormInput = defineEmits<{
  'input': [checked: boolean]
}>();

const value = ref(props.modelValue);

const handleInput = (event: InputEvent) => {
  const newValue = (event.target as HTMLInputElement).checked;
  value.value = newValue;

  if (typeof (props.validator) !== 'undefined' && props.validator != null) {
		props.validator.setValidationRuleSourceValue(props.validatorRuleId, value.value);
		let resultList = props.validator.validateRule(props.validatorRuleId);

		applyValidate(!props.validator.isValidationRuleValid(props.validatorRuleId), resultList);
	}
  emitFormInput('input', newValue);
};

function applyValidate(isInvalid: boolean, validationResultList: Array<IValidationResult>) {
    const inputElement = document.getElementById(`${props.inputId}`) as HTMLElement;
    const errorElement = document.getElementById(`${props.inputId}-error`) as HTMLElement;

    if (isInvalid) {
        inputElement.classList.add("has-error");
    } else {
        inputElement.classList.remove("has-error");
    }

    let errorString: Array<string> = [];
    validationResultList.forEach((validationResult) => {
        if (validationResult.result instanceof Error) {
            errorString.push(validationResult.result.message);
        }
    });
    errorElement.innerText = errorString.toString();
}



</script>
